"use client";

import React, { useState } from "react";
import SunsetModal from "../components/navbar/SunsetModal.tsx";
import Card from "antd/lib/card";

const { Meta } = Card;
import List from "antd/lib/list";
import { useRouter } from "next/navigation";
import { formatDateForPresentation } from "../utils/dt-format.ts";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button";
import { FaShuffle } from "react-icons/fa6";
import Row from "antd/lib/row";

// Hardcode to avoid querying firebase each time
export const PUZZLE_DATES = [
  "2024-02-12",
  "2024-02-13",
  "2024-02-14",
  "2024-02-15",
  "2024-02-16",
  "2024-02-17",
  "2024-02-18",
  "2024-02-19",
  "2024-02-20",
  "2024-02-21",
  "2024-02-22",
  "2024-02-23",
  "2024-02-24",
  "2024-02-25",
  "2024-02-26",
  "2024-02-27",
  "2024-02-28",
  "2024-02-29",
  "2024-03-01",
  "2024-03-02",
  "2024-03-03",
  "2024-03-04",
  "2024-03-05",
  "2024-03-06",
  "2024-03-07",
  "2024-03-08",
  "2024-03-10",
  "2024-03-11",
  "2024-03-12",
  "2024-03-13",
  "2024-03-14",
  "2024-03-15",
  "2024-03-16",
  "2024-03-17",
  "2024-03-18",
  "2024-03-19",
  "2024-03-20",
  "2024-03-21",
  "2024-03-22",
  "2024-03-23",
  "2024-03-24",
  "2024-03-25",
  "2024-03-26",
  "2024-03-27",
  "2024-03-28",
  "2024-03-29",
  "2024-03-30",
  "2024-03-31",
  "2024-04-01",
  "2024-04-02",
  "2024-04-03",
  "2024-04-04",
  "2024-04-05",
  "2024-04-06",
  "2024-04-07",
  "2024-04-08",
  "2024-04-10",
  "2024-04-11",
  "2024-04-12",
  "2024-04-13",
  "2024-04-14",
  "2024-04-15",
  "2024-04-16",
  "2024-04-17",
  "2024-04-18",
  "2024-04-19",
  "2024-04-20",
  "2024-04-21",
  "2024-04-22",
  "2024-04-23",
  "2024-04-24",
  "2024-04-25",
  "2024-04-26",
  "2024-04-27",
  "2024-04-28",
  "2024-04-29",
  "2024-04-30",
  "2024-05-01",
  "2024-05-02",
  "2024-05-03",
  "2024-05-04",
  "2024-05-05",
  "2024-05-06",
  "2024-05-07",
  "2024-05-08",
  "2024-05-10",
  "2024-05-11",
  "2024-05-12",
  "2024-05-13",
  "2024-05-14",
  "2024-05-15",
  "2024-05-16",
  "2024-05-17",
  "2024-05-18",
  "2024-05-19",
  "2024-05-20",
  "2024-05-21",
  "2024-05-22",
  "2024-05-23",
  "2024-05-24",
  "2024-05-25",
  "2024-05-26",
  "2024-05-27",
  "2024-05-28",
  "2024-05-29",
  "2024-05-30",
  "2024-05-31",
  "2024-06-01",
  "2024-06-02",
  "2024-06-03",
  "2024-06-04",
  "2024-06-05",
  "2024-06-06",
  "2024-06-07",
  "2024-06-08",
];

const NUM_PICTURES = 44;

const AllPuzzlesPage = () => {
  const router = useRouter();
  const [sunsetOpen, setSunsetOpen] = useState(false);
  return (
    <>
      <SunsetModal
        promptedOpen={sunsetOpen}
        closePrompted={() => setSunsetOpen(false)}
      />
      <div
        style={{
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={4}>בחרו חיבורים!</Typography.Title>
        <Row>
          <Button
            size={"large"}
            type="primary"
            style={{ background: "#b5e352" }}
            icon={<FaShuffle />}
            onClick={() => {
              const idx = Math.floor(Math.random() * PUZZLE_DATES.length);
              return router.push(`/puzzle/${PUZZLE_DATES[idx]}`);
            }}
          >
            <b>בחר לי חיבורים! </b>
          </Button>
          <Button
            size={"large"}
            type="primary"
            style={{ background: "#b5e352", marginRight: "10px" }}
            onClick={() => {
              return router.push(`/custom`);
            }}
          >
            <b>אני רוצה ליצור חיבורים משלי!</b>
          </Button>
        </Row>
        <List
          style={{
            marginTop: "5vh",
            marginBottom: "5vh",
          }}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 2,
            xxl: 2,
          }}
          dataSource={[...PUZZLE_DATES].reverse()}
          renderItem={(puzzle, idx) => (
            <List.Item>
              <Card
                hoverable
                cover={
                  <img
                    alt={puzzle}
                    src={`/puzzles/puzzle${(idx % NUM_PICTURES) + 1}.webp`}
                  />
                }
                aria-label={`חיבורים ${formatDateForPresentation(puzzle)}`}
                onClick={() => router.push(`/puzzle/${puzzle}`)}
                bordered={false}
              >
                <Meta title={`חיבורים ${formatDateForPresentation(puzzle)}`} />
              </Card>
            </List.Item>
          )}
        />
      </div>
      );
    </>
  );
};
export default AllPuzzlesPage;
