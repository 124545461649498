"use client";
import Col from "antd/lib/col";
import Modal from "antd/lib/modal";
import Typography from "antd/lib/typography";
import { useLocalStorageValue } from "../../utils/useLocalStorageFlag";
import { sizes } from "../../styles/sizes.ts";
import Button from "antd/lib/button";
import Image from "antd/lib/image";
import aboutImg from "../../../public/logo.webp";
import BuyMeACoffee from "../BuyMeACoffee.tsx";

const SunsetModal = ({
  promptedOpen,
  closePrompted,
}: {
  promptedOpen: boolean;
  closePrompted: () => void;
}) => {
  const [isAcknowledged, setAck] = useLocalStorageValue(
    "sunset",
    false,
    Boolean,
  );
  console.log({
    isAcknowledged,
    promptedOpen,
    open: !isAcknowledged || promptedOpen,
  });
  const closeCallback = () => {
    setAck(true);
    closePrompted();
  };
  return (
    <Modal
      open={!isAcknowledged || promptedOpen}
      onCancel={closeCallback}
      onOk={closeCallback}
      okText="הבנתי, בוא נשחק!"
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { direction: "rtl" } }}
    >
      <Col dir="rtl" style={{ width: "100%" }}>
        <Typography.Title level={3}>
          הודעה חשובה: הפסקת הפאזלים היומיים
        </Typography.Title>
        <SunsetContent />
      </Col>
    </Modal>
  );
};

const SunsetContent = () => {
  return (
    <div
      dir="rtl"
      style={{
        padding: "9px",
        maxWidth: sizes.viewWidth,
      }}
    >
      <Typography.Paragraph style={{ textAlign: "justify" }}>
        החל מיום שבת, 8 ביוני 2024, נפסיק להוציא פאזלים יומיים חדשים. לעומת זאת,
        נחשוף את כל פאזלי העבר שהוצגו באתר עד כה, יותר מ100 פאזלים. ובנוסף את
        האפשרות לייצר חיבורים בעצמכם ולחלוק אותם עם חבריכם
      </Typography.Paragraph>
      <Typography.Paragraph style={{ textAlign: "justify" }}>
        חיבורים התחיל כרעיון פשוט ופרוייקט צד קטן, לייצר פאזלים יומיים בדומה
        למשחק&nbsp;
        <Button
          style={{ padding: "0", display: "inline", margin: 0 }}
          type="link"
          href="https://www.nytimes.com/games/connections"
        >
          Connections
        </Button>
        .&nbsp; לא היינו יכולים לצפות להצלחה הגדולה של המשחק. חיבורים הפך לאתר
        פופולרי בקרב קהל רחב בישראל, והפך לאתנחתה יומית של אלפי ישראלים.
      </Typography.Paragraph>
      <Typography.Paragraph style={{ textAlign: "justify" }}>
        אם אתם הייתם מאלו ששלחו לנו פידבק לגבי המשחק, הצעות לשיפור, הצעות
        קטגוריות, חלקתם עם חברכם ברשתות החברתיות, או אפילו רק יצא לכם לפתור כמה
        חיבורים, אנחנו מודים לך מכל הלב. הפכתם את הפרוייקט הזה לחוויה מיוחדת
        מאוד עבורינו, ואנחנו מקווים שיצא לנו לשמח ולאתגר אתכם
      </Typography.Paragraph>
      <Col span={14} offset={5}>
        <Image
          alt="חיבורים"
          preview={false}
          src={aboutImg.src}
          width="100%"
          height="100%"
        />
      </Col>
      <br />
      <Typography.Paragraph style={{ textAlign: "justify" }}>
        אם נהנתם מהמשחק אנחנו נשמח מאוד אם תפרגנו לנו בכוס קפה :)
      </Typography.Paragraph>
      <BuyMeACoffee />
      <br />
      <br />
      {/*<script key={'buymeacofee'} type="text/javascript" src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" data-name="bmc-button" data-slug="hiburim" data-color="#FFDD00" data-emoji="☕" data-font="Cookie" data-text="Buy me a coffee" data-outline-color="#000000" data-font-color="#000000" data-coffee-color="#ffffff" ></script>*/}
      <Typography.Paragraph style={{ textAlign: "justify" }}>
        תודה רבה לכולכם על התמיכה שלכם.
      </Typography.Paragraph>
      <Typography.Paragraph style={{ textAlign: "justify" }}>
        צוות חיבורים
      </Typography.Paragraph>
    </div>
  );
};

export default SunsetModal;
