export function formatDate(date: Date) {
  const { year, month, day } = getDateParts(date);
  return `${year}-${month}-${day}`;
}

export function formatDateForPresentation(date: Date | string) {
  if (typeof date === "string") {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }
  const { year, month, day } = getDateParts(date);
  return `${day}/${month}/${year}`;
}

export const getDateParts = (
  dt: Date,
): { year: string; month: string; day: string } => {
  return new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "Asia/Jerusalem",
  })
    .formatToParts(dt)
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, Object.create(null));
};

export const formatHebrewTime = (secondsNum: number) => {
  const seconds =
    secondsNum % 60 === 0
      ? ""
      : secondsNum % 60 === 1
        ? "שנייה"
        : `${Math.round(secondsNum % 60)} שניות`;
  const minutes =
    secondsNum < 60
      ? ""
      : secondsNum < 120
        ? "דקה"
        : `${Math.floor(secondsNum / 60)} דקות`;
  if (minutes && seconds) {
    return `${minutes} ו-${seconds}`;
  } else if (minutes) {
    return minutes;
  } else {
    return seconds;
  }
};
